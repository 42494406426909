<template>
  <div>
    <page-toolbar title="Account"></page-toolbar>
    <v-container>
      <page-title>Account</page-title>

      <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <div class="mx-auto py-12" style="width: 240px">
              <v-avatar size="240px">
                <v-img :src="resourceUrl($store.state.loginInfo.profileImage)"></v-img>
              </v-avatar>
            </div>

            <v-card-text>
              <div class="text-center">
                <h3>{{ $store.state.loginInfo.firstName + ' ' + $store.state.loginInfo.lastName }}</h3>
                <span :title="$store.state.loginInfo.email">{{ $store.state.loginInfo.email }}</span>
              </div>
            </v-card-text>

            <v-card-actions class="mt-2">
              <div class="d-flex flex-column" style="width:100%">
                <v-btn class="mb-1" block color="primary" dark @click.stop="dialog = !dialog">Change Password</v-btn>
                <v-btn class="mb-1" block color="primary" dark @click.stop="$refs.fileupload.click()">Change Image</v-btn>
                <v-btn class="mb-1" block color="red" dark @click="signOut()">Log Out</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="8">
          <v-card :loading="loadingUserInfo">
            <v-card-title>Account Information</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <span>Username</span>
                  <v-spacer></v-spacer>
                  <span>{{ userInfo.username }}</span>
                </v-list-item>
                <v-list-item>
                  <span>First Name</span>
                  <v-spacer></v-spacer>
                  <span>{{ userInfo.first_name }}</span>
                </v-list-item>
                <v-list-item>
                  <span>Last Name</span>
                  <v-spacer></v-spacer>
                  <span>{{ userInfo.last_name }}</span>
                </v-list-item>
                <v-list-item>
                  <span>Email</span>
                  <v-spacer></v-spacer>
                  <span :title="userInfo.email">{{ userInfo.email }}</span>
                </v-list-item>
                <v-list-item>
                  <span>Type</span>
                  <v-spacer></v-spacer>
                  <span>{{ userInfo.user_type }}</span>
                </v-list-item>
                <v-list-item>
                  <span>Last Login</span>
                  <v-spacer></v-spacer>
                  <span>{{ userInfo.last_login_date }}</span>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="600px">
      <v-form v-model="formChangePasswordValid" ref="refFormChangePassword">
        <v-card>
          <v-card-title>Change Password</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field outlined required v-model="formData.current_password" :rules="formDataRules.current_password" :error-messages="formErrors.current_password" type="password" label="Current Password"></v-text-field>
            <v-divider class="mb-2"></v-divider>
            <v-text-field outlined required v-model="formData.new_password" :rules="formDataRules.new_password" :error-messages="formErrors.new_password" type="password" label="New password"></v-text-field>
            <v-text-field outlined required v-model="formData.new_password_conf" :rules="formDataRules.new_password_conf" :error-messages="formErrors.new_password_conf" type="password" label="Confirm New Password" ref="refconfirmPassword"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="changePassword">Submit</v-btn>
            <v-btn @click.stop="dialog = !dialog">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>


    <input type="file" ref="fileupload" name="fileupload" style="display:none;" v-on:change="changeImage" accept="image/*" />
  </div>
</template>

<script>
export default {
  name: "Account",
  components: {},
  data() {
    return {
      loadingUserInfo: false,
      userInfo: {},
      dialog: false,
      formChangePasswordValid: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      verificationCode: "",
      currentPasswordType: "password",
      newPasswordType: "password",
      confirmPasswordType: "password",
      currentPasswordRules: [(v) => !!v || "Current password is required"],
      newPasswordRules: [(v) => !!v || "New Password is required"],
      confirmPasswordRules: [(v) => !!v || "Confirm Password is required"],

      formData: {
        current_password: "",
        new_password: "",
        new_password_conf: "",
      },

      formDataRules: {
        current_password: [(v) => !!v || "Current password is required"],
        new_password: [(v) => !!v || "New Password is required"],
        new_password_conf: [(v) => !!v || "Confirm New Password is required"],
      },

      formErrors: {
        current_password: "",
        new_password: "",
        new_password_conf: "",
      },

      fileupload: null,
    };
  },

  methods: {
    changePassword() {
      this.$refs.refFormChangePassword.validate();

      if (this.formChangePasswordValid) {
        if (this.newPassword !== this.confirmPassword) {
          this.$refs.refconfirmPassword.focus();
          this.showAlert("error", "Confirm Password invalid!");
          return;
        }

        this.showLoadingOverlay(true);

        var formData = new FormData();
        formData.append("current_password", this.formData.current_password);
        formData.append("new_password", this.formData.new_password);
        formData.append("new_password_conf", this.formData.new_password_conf);

        this.$axios
          .post("account/changepassword", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              this.AxiosStorageRemoveAll();

              this.$router.push("/login");
            } else {
              this.formErrors = resData.data.errors;
              this.showAlert(resData.status, resData.message);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.showLoadingOverlay(false);
            console.log("FAILURE!!");
          });
      }
    },
    async getData(refresh) {
      this.loadingUserInfo = true;
      if (refresh) this.AxiosStorageRemove("GET", "account/info");
      var config = {
        params: {},
        cacheConfig: true,
      };
      await this.$axios
        .get("account/info", config)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.userInfo = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loadingUserInfo = false;
        })
        .catch((error) => {
          if (error.response) {
            this.$awn.alert("Internal Server Error");
            console.log(error.response.headers);
          } else {
            console.log("Error", error.message);
          }
          this.loadingUserInfo = false;
        });
    },
    refreshData() {
      this.getData();
    },
    searchData() {
      this.selectedStatus = -1;
    },

    async changeImage() {
      this.loading = true;
      this.fileupload = this.$refs.fileupload.files[0];
      if (this.fileupload === "") return;
      if (!confirm("Upload File?")) return;

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("user_img", this.fileupload);
      await this.$axios
        .post("account/update-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.$store.state.loginInfo.profileImage = resData.data.user_img
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          console.log("FAILURE!!");
        });
    },
  },

  mounted() {
    this.requiredLogin();
    this.getData()
  },
};
</script>